@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url("../font/Inter-Regular.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"), url("../font/Inter-Medium.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"), url("../font/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Khand-Medium";
  src: local("Khand-Medium"), url("../font/Khand-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Khand-SemiBold";
  src: local("Khand-SemiBold"),
    url("../font/Khand-SemiBold.ttf") format("truetype");
  font-weight: 700;
}
a{
  text-decoration: none;
}

body {
  font-family: "Inter-Regular" !important;
}

.checkBxStle input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 11px;
  width: 5px;
  height: 10px;
  border: solid rgba(0, 12, 20, 1);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkBxStle label:before {
  content: "";
  appearance: none;
  background-color: transparent;
  border: 1px solid rgba(205, 209, 224, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  vertical-align: top;
  margin-right: 5px;
}

.qr-reader {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}